import * as React from 'react'
import { graphql } from 'gatsby'
import Pagina from '../components/Pagina'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'
// import Swiper from 'react-id-swiper'

import grafismo from '../images/Home/Grafismo.svg'
import Banner from '../components/Banner/index.js'
// import compreensao from '../images/Governance/icons/chat.svg'
// import determinacao from '../images/Governance/icons/escritorio.svg'
// import transparencia from '../images/Governance/icons/lupa.svg'
// import educacao from '../images/Governance/icons/man.svg'
// import humildade from '../images/Governance/icons/meta.svg'
// import uniao from '../images/Governance/icons/people.svg'
// import integridade from '../images/Governance/icons/two.svg'
// import reconhecimento from '../images/Governance/icons/reconhecimento.svg'

import './styles/governance.scss'

// const Banner = ({ banner }) => {
//   const intl = useIntl()
//   return (
//     <div className="position-relative" id='BannerGovernance'>
//       <GatsbyImage image={banner} alt='Banner Governança' className="banner" />
//       <div className="position-absolute above w-100 h-100 align-items-end d-flex fixed-top z-index-1">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-10 col-12 custom-banner">
//               <div className="col-lg-8 p-0 d-flex justify-content-start">
//                 <div className="p-0 col-lg-12">
//                   <h1 className="font-Spinnaker text-white">{parse(intl.formatMessage({ id: 'governanca.banner.titulo' }))}</h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

const NossaGovernanca = () => {
  const intl = useIntl()
  return (
    <div className="container-fluid m-0 p-0 pb-5" id='SectionNossaGovernanca'>
      <div className="position-relative">
        <div className="container">
          <div className='floatLineGreen'></div>
          <div className="row py-lg-5 p-0 m-0 ">
            <div className="col-lg-7 text-gray lineGreen p-0">
              <p className="mb-4">{parse(intl.formatMessage({ id: 'governanca.sectionNossaGovernanca.paragrafo1' }))}</p>
              <p className='mb-4'>{parse(intl.formatMessage({ id: 'governanca.sectionNossaGovernanca.paragrafo2' }))}</p>
            </div>
            <div className="col-5 position-absolute p-0 grafismoDiv d-lg-block d-none">
              <img src={grafismo} alt="Icone Grafismo" width='650px' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// const ValoresGovernanca = () => {
//   const intl = useIntl()
//   const Molde = (props) => {
//     return (
//       <div className='col-lg-1 mr-lg-5 mb-lg-0 mb-5 col-3'>
//         <div className='lineGreen'>
//           <div className='d-flex justify-content-center'>
//             <img src={props.icon} className='mb-3 icons' alt={props.alt} />
//           </div>
//           <p className='text-center text-white pb-1'>{props.text}</p>
//         </div>
//       </div>
//     )
//   }
//   return (
//     <div className='container-fluid py-5' id='SectionValoresGovernanca'>
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-12 customPadding">
//             <div className="col-lg-8 p-0">
//               <h2 className='text-white pb-5'>{parse(intl.formatMessage({ id: 'governanca.sectionValoresGovernanca.titulo' }))}</h2>
//             </div>
//             <div className='row mt-4'>
//               <Molde icon={transparencia} text={intl.formatMessage({ id: 'governanca.sectionValoresGovernanca.valores.transparencia' })} alt='Icone Lupa' />
//               <Molde icon={educacao} text={intl.formatMessage({ id: 'governanca.sectionValoresGovernanca.valores.educacao' })} alt='Icone homem segurando uma maleta' />
//               <Molde icon={humildade} text={intl.formatMessage({ id: 'governanca.sectionValoresGovernanca.valores.humildade' })} alt='Icone de três pessoas alcançando uma meta' />
//               <Molde icon={integridade} text={intl.formatMessage({ id: 'governanca.sectionValoresGovernanca.valores.integridade' })} alt='Icone duas pessoas batendo as mãos' />
//               <Molde icon={uniao} text={intl.formatMessage({ id: 'governanca.sectionValoresGovernanca.valores.uniao' })} alt='Icone cinco homens' />
//               <Molde icon={compreensao} text={intl.formatMessage({ id: 'governanca.sectionValoresGovernanca.valores.compreensao' })} alt='Icone três pessoas conversando' />
//               <Molde icon={determinacao} text={intl.formatMessage({ id: 'governanca.sectionValoresGovernanca.valores.determinacao' })} alt='Icone homem no escritório' />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

const EstruturaGovernanca = ({ estrutura }) => {
  const intl = useIntl()
  return (
    <div className='container-fluid py-5 bg-blue' id='SectionEstruturaGovernanca'>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 customPadding position-relative">
            <div className="col-lg-8 p-0">
              <h2 className='text-white pb-5'>{intl.formatMessage({ id: 'governanca.sectionEstruturaGovernanca.titulo' })}</h2>
            </div>
            <div className='d-flex justify-content-center pb-5'>
              <GatsbyImage image={estrutura} alt='Estrutura de Governança' className='custom-width' />
            </div>
            <div className='d-lg-flex mt-5'>
              <div className='col-lg-3 col-12 mb-lg-0 mb-5'>
                <h4 className='text-green'>{parse(intl.formatMessage({ id: 'governanca.sectionEstruturaGovernanca.estrutura1.titulo' }))}</h4>
                <hr className='divisor ml-0' />
                <p className='text-white'>{intl.formatMessage({ id: 'governanca.sectionEstruturaGovernanca.estrutura1.texto' })}</p>
              </div>
              <div className='col-lg-3 col-12 mb-lg-0 mb-5'>
                <h4 className='text-green'>{parse(intl.formatMessage({ id: 'governanca.sectionEstruturaGovernanca.estrutura2.titulo' }))}</h4>
                <hr className='divisor ml-0' />
                <p className='text-white'>{intl.formatMessage({ id: 'governanca.sectionEstruturaGovernanca.estrutura2.texto' })}</p>
              </div>
              <div className='col-lg-3 col-12 mb-lg-0 mb-5'>
                <h4 className='text-green'>{parse(intl.formatMessage({ id: 'governanca.sectionEstruturaGovernanca.estrutura3.titulo' }))}</h4>
                <hr className='divisor ml-0' />
                <p className='text-white'>{intl.formatMessage({ id: 'governanca.sectionEstruturaGovernanca.estrutura3.texto' })}</p>
              </div>
              <div className='col-lg-3 col-12 mb-lg-0 mb-5'>
                <h4 className='text-green'>{parse(intl.formatMessage({ id: 'governanca.sectionEstruturaGovernanca.estrutura4.titulo' }))}</h4>
                <hr className='divisor ml-0' />
                <p className='text-white'>{intl.formatMessage({ id: 'governanca.sectionEstruturaGovernanca.estrutura4.texto' })}</p>
              </div>
            </div>
            <div className="col-5 position-absolute p-0 grafismoDiv d-lg-block d-none">
              <img src={grafismo} alt="Icone Grafismo" width='650px' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// const EstruturaDeliberacao = ({ estrutura }) => {
//   const intl = useIntl()
//   return (
//     <div className='container-fluid py-5' id='SectionEstruturaDeliberacao'>
//       <div className="container">
//         <div className="row">
//           <div className="row col-lg-12 customPadding">
//             <div className="col-lg-3 mr-5 p-0">
//               <h2 className='text-blue-light pb-3'>{parse(intl.formatMessage({ id: 'governanca.sectionEstruturaDeliberacao.titulo' }))}</h2>
//               <div className='line-gradient mb-5'></div>
//               <p className='text-gray mt-0'>Uma organização que envolve os órgãos de administração e os sócios, podendo contar com o Conselho de Administração e a Assembleia Geral.</p>
//               <p className='text-gray mt-4'>É assim que estamos estruturados para continuar fortalecidos, seguindo nosso caminho em direção ao futuro.</p>
//             </div>
//             <div className='col-lg-5 ml-5'>
//               <GatsbyImage image={estrutura} alt='Estrutura de Deliberação' className='w-100 mt-lg-0 mt-4' />
//             </div>
//           </div>
//           <div className="col-5 position-absolute p-0 grafismoDiv d-lg-block d-none">
//             <img src={grafismo} alt="Icone Grafismo" />
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// const Reconhecimento = () => {
//   const Molde = ({ icon, title, text }) => {
//     return (
//       <div className="d-flex justify-content-center">
//         <div className="text-center width-custom">
//           <img src={icon} alt="Inovacao" width="100" height="100" />
//           <div className="mb-lg-0 mb-5">
//             <h4 className="text-white text-center">{ title }</h4>
//             <p className="text-white text-center">{ text }</p>
//           </div>
//         </div>
//       </div>
//     )
//   }
//   const params = {
//     navigation: {
//       nextEl: '.swiper-button-next',
//       prevEl: '.swiper-button-prev'
//     },
//     pagination: {
//       el: '.swiper-pagination',
//       clickable: true
//     },
//     slidesPerView: 1,
//     spaceBetween: 0,
//     breakpoints: {
//       480: {
//         slidesPerView: 1,
//         spaceBetween: 0
//       },
//       1023: {
//         slidesPerView: 3,
//         spaceBetween: 0
//       }
//     }
//   }
//   const intl = useIntl()
//   return (
//     <div className="container-fluid px-0" id="SectionReconhecimentos">
//       <div className="container">
//         <div className="d-flex justify-content-start">
//           <h2 className='text-white pb-5'>{intl.formatMessage({ id: 'governanca.sectionReconhecimentos.titulo' })}</h2>
//         </div>
//         <Swiper {...params}>
//           <div>
//             <Molde icon={reconhecimento} title={intl.formatMessage({ id: 'governanca.sectionReconhecimentos.premioFamilia.titulo' })} text={intl.formatMessage({ id: 'governanca.sectionReconhecimentos.premioFamilia.texto' })} />
//           </div>
//           <div>
//             <Molde icon={reconhecimento} title={intl.formatMessage({ id: 'governanca.sectionReconhecimentos.premioFamilia.titulo' })} text={intl.formatMessage({ id: 'governanca.sectionReconhecimentos.premioFamilia.texto' })} />
//           </div>
//           <div>
//             <Molde icon={reconhecimento} title={intl.formatMessage({ id: 'governanca.sectionReconhecimentos.premioFamilia.titulo' })} text={intl.formatMessage({ id: 'governanca.sectionReconhecimentos.premioFamilia.texto' })} />
//           </div>
//         </Swiper>
//       </div>
//     </div>
//   )
// }

const Governanca = ({ data, pageContext }) => {
  const intl = useIntl()

  return (
    <div>
      <Pagina pagina={data.pagina} />
      {/* <Banner banner={data.bannerDesktop.childImageSharp.gatsbyImageData} /> */}
      <Banner title={parse(intl.formatMessage({ id: 'governanca.banner.titulo' }))} banner={data.bannerDesktop.childImageSharp.gatsbyImageData} alt='Banner Governança' />
      <NossaGovernanca />
      {/* <ValoresGovernanca /> */}
      <EstruturaGovernanca estrutura={data.estruturaGovernanca.childImageSharp.gatsbyImageData} />
      {/* <EstruturaDeliberacao estrutura={data.estruturaDeliberacao.childImageSharp.gatsbyImageData} /> */}
      {/* <Reconhecimento /> */}
    </div>
  )
}

export default Governanca

export const governanceQuery = graphql`
  query governanceQuery($locale: String){
    pagina: contentfulPagina(path: {eq: "/governanca/"}, node_locale: {regex: $locale}) {
      contentful_id
      node_locale
      metaTitle
      metaDescription
      pageSummary
      pageTitle
      path
      tags
    }
    bannerDesktop: file(relativePath: {eq: "Governance/banner.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    estruturaGovernanca: file(relativePath: {eq: "Governance/estrutura.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    estruturaDeliberacao: file(relativePath: {eq: "Governance/estrutura2.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }`
